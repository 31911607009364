
@import url('https://fonts.googleapis.com/css?family=Montserrat:regular,bold,italic,500&subset=latin,latin-ext');
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');



body {
  margin: 0;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
table, tr, td, * {
  font-family: Montserrat;
  font-size: 15px;
}
h1,h2,h3,h4,h5,h6 {
  font-weight: 600 !important;
}
h1 {
  font-size: 28px !important;
}
.container.full-width {
  width: 100% !important;
  max-width: 100% !important;
}
.nav-sidebar {
  background-color: #f7f7f7;
  min-height: 100vh;
  padding: 20px;

}
.nav-sidebar .nav-link{
  color: #222;
    font-weight: bold;
    font-family: 'Montserrat';
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 8px ;
    border-bottom: solid 1px #eaeaea;
    padding-bottom: 15px;
}
.nav-sidebar .nav-link i {
  margin-right: 10px;
  margin-left: 5px;
}
.nav-link:focus, .nav-link:hover {
  color: #00A8E1 !important;
}
.nav-logo {
  width: 180px;
    margin-bottom: 20px;
}
.pad-20 {
  padding: 20px !important;
}
.thead-dark th {
  background-color: #00A8E1 !important;
  color: #fff;
  border-bottom: 0px !important;
}
.table {
  border: solid 1px #cacaca;
  border-radius: 20px !important;
}
span.badge {
  background: #333;
  margin-right: 5px;
}

.table tr th, .table  tr td {
  padding: 10px;
  font-size: 12px;
  font-weight: 500;
}
.login-box {
  background-color: #00A8E1;
}
.login-container {
  width: 100% !important;
  max-width: 100% !important;
  background-color: #00A8E1;
  min-height: 100vh;
  align-items: center;
}
.fpad {
  padding: 10px !important;
  margin-top: 10px;
}

.login-box .card {
  background: rgba(255,255,255,0.8);
  font-weight: 600 !important;
}








table.boostrap4_table_head_dark_striped_rounded_with_shadow {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    box-sizing: border-box;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
}

table.boostrap4_table_head_dark_striped_rounded_with_shadow thead th {
    font-size: 1rem;
    line-height: 1.5;
    border-collapse: collapse;
    box-sizing: border-box;
    text-align: inherit;
    padding: .75rem;
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
    color: #fff;
    background-color: #212529;
    border-color: #32383e;
    font-size: 12px;
}

table.boostrap4_table_head_dark_striped_rounded_with_shadow td {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    border-collapse: collapse;
    box-sizing: border-box;
    padding: .75rem;
    vertical-align: top;
    font-size: 12px;
}

table.boostrap4_table_head_dark_striped_rounded_with_shadow tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}

table.boostrap4_table_head_dark_striped_rounded_with_shadow th:first-of-type {
    border-top-left-radius: 10px;
}

table.boostrap4_table_head_dark_striped_rounded_with_shadow th:last-of-type {
    border-top-right-radius: 10px;
}

table.boostrap4_table_head_dark_striped_rounded_with_shadow tr:last-of-type td:first-of-type {
    border-bottom-left-radius: 10px;
}

table.boostrap4_table_head_dark_striped_rounded_with_shadow tr:last-of-type td:last-of-type {
    border-bottom-right-radius: 10px;
}






/* ----------- Non table related styles */

.table_container {
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
    
}

body {
    font-family: sans-serif;
}
.main-container {
  padding: 30px !important;
    padding-top: 20px !important;
}
.chart-container.card {
  padding: 20px;
    box-shadow: 0px 0px 45px rgba(0,0,0,0.30);
    border: 0px;
    margin-bottom: 30px;
    min-height: 50vh;
}
.main-container >  .container  {
  padding-left: 0px !important;
}
.chart-title {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600 !important;
}

.chart-legend.facebook {
  background-color: #1e2055;
}
.chart-legend.seo {
  background-color: #ef652f;
}
.chart-legend.ppc {
  background-color: #72bd4b;
}
.chart-legend.referral {
  background-color: #8e2f8e;
}

.chart-legend.strategic-partner {
background-color: #2f9ed5;
}
.chart-legend.repeat-client {
  background-color: #fde142;
}

.chart-legend.retargeting {
  background-color: #003A88;
} 
.chart-legend.relationship {
  background-color: #006DB2;
} 




.chart-legend {
  width: 20px !important;
    height: 10px;
    display: inline-block;
    border-radius: 4px;
    margin-right: 8px;
    width: 12px !important;
    height: 12px !important;
    border-radius: 50%;
}
.buttons-right {
  text-align: right;
}
.btn-primary {
  background-color: #00A8E1 !important;
  border-color: #00A8E1 !important;
}
#btnPieChart {
display: none;
}

.form-control.white {
  background-color: #fff;
}
.legend-data {
  position: absolute;
    right: 20px;
}
.hide {
  display: none;
}
div.show {
  display: block;
}

#div_from_date, #div_to_date {
margin-top: 10px;
}
.tr-color {
  color:  rgba(255,255,255,0);
}

.form-control.invisible {
  visibility: hidden !important;
}
.dc1 {
background-color:#003A88;
}
.dc2 {
  background-color:#000000;
}
.dc3 {
  background-color:#cacaca;
}
.dc4 {
  background-color:#373737;
}
.dc5 {
  background-color:#ff0000;
}
.dc6 {
  background-color:#00ff00;
}
.dc7 {
  background-color:#0000ff;
}
.dc8 {
  background-color:#ff00ff;
}
.dc9 {
  background-color:#00ffff;
}
.dc10 {
  background-color:#962fdf;
}

